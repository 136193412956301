<template>
  <div
    class="header-container"
    :style="
      'background-color:' +
      $config.dados.corprimaria +
      '; border-color: ' +
      $config.dados.corsecundaria
    "
  >
    <div class="header-container1">
      <div class="header-container2" @click="goHome()">
        <!-- <img alt="logo" :src="$config.dados.logowhite" class="header-image" /> -->
        <img alt="logo" :src="$config.dados.logobg" class="header-image" />
      </div>
      <div class="header-container3">
        <v-btn text elevation="0" v-if="!$axios.getLocal('session')" @click="goHome()">
          <span class="header-title">{{ $t_("home") }}</span>
        </v-btn>

        <v-btn
          @click="$usuario.showModalLogin = !$usuario.showModalLogin"
          text
          elevation="0"
          class="header-title"
          v-if="!$axios.getLocal('session')"
        >
          {{ $t_("login_cadastro") }}
          <v-icon class="ml-2">mdi-account-circle mdi-color-white</v-icon>
        </v-btn>

        <div class="header-container3" v-else>
          <v-btn text elevation="0" @click="goHome()">
            <span class="header-title">{{ $t_("home") }}</span>
          </v-btn>

          <v-btn text elevation="0" @click="goPedidos()">
            <span class="header-title">{{ $t_("meus_pedidos") }}</span>
          </v-btn>

          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text elevation="0">
                <span class="header-title" v-if="$axios.getLocal('session')">{{
                  $axios.getLocal("session").data.nome.indexOf(" ")
                    ? $axios.getLocal("session").data.nome.split(" ")[0]
                    : $axios.getLocal("session").data.nome
                }}</span>
                <v-icon color="white" class="ml-2"
                  >mdi-account-circle mdi-color-white</v-icon
                >
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-list dense nav class="text-left">
                  <v-list-item
                    v-for="(item, index) in perfil"
                    :key="index"
                    link
                    @click="operacao(item.link)"
                  >
                    <v-list-item-icon>
                      <v-icon :color="$config.dados.corprimaria">{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>

        <v-menu left bottom v-if="$config.dados.multilinguagem">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              elevation="0"
              class="px-0 header-login header-login1"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ $lang_.getLang().title }}</span>
              <svg viewBox="0 0 1024 1024" class="header-icon1">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
              <!-- <v-icon class="ml-md-2">mdi mdi-chevron-down mdi-color-white</v-icon> -->
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-once
              v-for="(item, index) in $lang_.langs"
              :key="index"
              @click="$lang_.setLang(item.lang)"
            >
              <v-list-item-title class="d-flex">
                <img
                  :src="item.img"
                  alt
                  width="20"
                  height="13"
                  loading="lazy"
                  class="mr-3"
                />
                {{ item.lang.substring(0, 2) | uppercase }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-menu left bottom v-if="$axios.getLocal('session') && $axios.getLocal('carrinho')">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon elevation="0" class="px-0 px-md-3" v-bind="attrs" v-on="on"
                            @click="$axios.rota('Checkout')">
                            <v-icon color="white">mdi-basket mdi-color-white</v-icon>
                            <v-badge v-if="$carrinho.resumo.qtd" :color="$config.dados.corsecundaria" dark :content="$carrinho.resumo.qtd" offset-x="10" offset-y="0"></v-badge>
                        </v-btn>
                    </template>
                </v-menu> -->
      </div>
    </div>
    <div class="superingresso-mobile">
      <v-app-bar :color="$config.dados.corprimaria" dark flat fixed>
        <v-container class="px-0 px-md-3 d-flex">
          <v-row align="center">
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-toolbar-title>
              <img
                alt="logo"
                :src="$config.dados.logobg"
                loading="lazy"
                class="superingresso-pointer"
                @click="goHome()"
                height="40"
              />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu left bottom v-if="$config.dados.multilinguagem">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  elevation="0"
                  class="px-0 header-login header-login1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ $lang_.getLang().title }}</span>
                  <svg viewBox="0 0 1024 1024" class="header-icon1">
                    <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                  </svg>
                  <!-- <v-icon class="ml-md-2">mdi mdi-chevron-down mdi-color-white</v-icon> -->
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-once
                  v-for="(item, index) in $lang_.langs"
                  :key="index"
                  @click="$lang_.setLang(item.lang)"
                >
                  <v-list-item-title class="d-flex">
                    <img
                      :src="item.img"
                      alt
                      width="20"
                      height="13"
                      loading="lazy"
                      class="mr-3"
                    />
                    {{ item.lang.substring(0, 2) | uppercase }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-container>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        fixed
        style="height: 100vh; width: 265px !important"
        hide-overlay
      >
        <v-list-item :style="'background-color: ' + $config.dados.corprimaria">
          <v-list-item-content>
            <v-list-item-title class="title">
              <img alt="logo" :src="$config.dados.logobg" width="160" loading="lazy" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav class="text-left">
          <v-list-item
            v-for="(item, index) in menu"
            :key="index"
            link
            @click="$axios.rota(item.link)"
          >
            <v-list-item-icon>
              <v-icon :color="$config.dados.corprimaria">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense nav class="text-left">
          <v-list-group
            :value="false"
            prepend-icon="mdi-file-document mdi-color-gray"
            :color="$config.dados.corprimaria"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t_("politicas") }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="(item, index) in politicas"
              :key="index"
              link
              @click="getLink(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>

        <v-list dense nav class="text-left">
          <v-list-group
            :value="false"
            prepend-icon="mdi-sitemap mdi-color-gray"
            :color="$config.dados.corprimaria"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t_("mapa_site") }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(item, index) in mapa"
              :key="index"
              link
              @click="getLink(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>

        <v-divider v-if="$axios.getLocal('session')"></v-divider>

        <v-btn
          elevation="0"
          @click="$usuario.showModalLogin = !$usuario.showModalLogin"
          :color="$config.dados.corprimaria"
          width="240"
          v-if="!$axios.getLocal('session')"
          class="mb-10 mt-3 ml-2"
        >
          <v-icon color="white">mdi-account-circle mdi-color-white</v-icon>
          <span class="ml-2" style="color: white; font-size: 10px">{{
            $t_("login_cadastro")
          }}</span>
        </v-btn>

        <v-list dense nav class="text-left" v-if="$axios.getLocal('session')" disabled>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title
                ><h2>
                  {{ $axios.getLocal("session").data.nome.split(" ")[0] }}
                </h2></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dense nav class="text-left" v-if="$axios.getLocal('session')">
          <v-list-item
            v-for="(item, index) in perfil"
            :key="index"
            link
            @click="operacao(item.link)"
          >
            <v-list-item-icon>
              <v-icon :color="$config.dados.corprimaria">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <ModalLogin />
    <v-dialog v-model="showRedefinirsenha" max-width="420px">
      <v-card>
        <v-card-title>
          {{ $t_("trocar_senha") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <label>{{ $t_("senha_antiga") }}</label>
              <v-text-field
                :v-if="$usuario.cadastro.old_password"
                class="mt-3"
                v-model="$usuario.cadastro.old_password"
                dense
                outlined
                :type="show ? 'text' : 'password'"
                :append-icon="
                  show ? 'mdi-eye mdi-color-gray' : 'mdi-eye-off mdi-color-gray'
                "
                @click:append="show = !show"
                placeholder="*******"
                tabindex="1"
                :error-messages="$usuario.cadastro.error.old_password"
                @input="$usuario.cadastro.error.old_password = []"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <label required>{{ $t_("NOVA_SENHA") }}</label>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="
                      cursor: help;
                      position: relative;
                      margin-left: 5px;
                      width: 17px;
                      height: 17px;
                    "
                  >
                    mdi-information mdi-color-gray
                  </v-icon>
                </template>
                <span v-once>{{ $t_("tip_senha") }}</span>
              </v-tooltip>
              <v-text-field
                :v-if="$usuario.cadastro.senha"
                class="mt-3"
                v-model="$usuario.cadastro.senha"
                dense
                outlined
                :type="show1 ? 'text' : 'password'"
                :append-icon="
                  show1 ? 'mdi-eye mdi-color-gray' : 'mdi-eye-off mdi-color-gray'
                "
                @click:append="show1 = !show1"
                placeholder="*******"
                :error-messages="$usuario.cadastro.error.senha"
                @input="$usuario.cadastro.error.senha = []"
                tabindex="2"
              >
              </v-text-field>
              <v-progress-linear
                :v-if="$usuario.cadastro.senha != ''"
                v-model="chekingPassword"
                :color="
                  chekingPassword < 60 ? 'red' : chekingPassword < 90 ? 'orange' : 'green'
                "
                height="4"
                style="position: relative; top: -26px"
              >
              </v-progress-linear>
            </v-col>
            <v-col cols="12" class="py-0">
              <label required>{{ $t_("confirmar_senha") }}</label>
              <v-text-field
                :v-if="$usuario.cadastro.senha2"
                class="mt-3"
                v-model="$usuario.cadastro.senha2"
                dense
                outlined
                :type="show2 ? 'text' : 'password'"
                :append-icon="
                  show2 ? 'mdi-eye mdi-color-gray' : 'mdi-eye-off mdi-color-gray'
                "
                @click:append="show2 = !show2"
                placeholder="*******"
                tabindex="3"
                :error-messages="$usuario.cadastro.error.senha2"
                @input="$usuario.cadastro.error.senha2 = []"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-4">
          <v-btn
            tabindex="11"
            class=""
            :color="$config.dados.corprimaria"
            @click="$usuario.trocarSenha()"
            block
            elevation="0"
            style="color: white"
          >
            <strong>{{ $t_("trocar_senha") }}</strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {
    ModalLogin: () => import("@/components/modal-login.vue"),
  },
  props: {
    qtd: {
      type: String,
      default: "0",
    },
  },
  watch: {
    drawer(e) {
      var cookies = document.getElementsByClassName("animate-cookies-button");
      if (cookies && cookies.length > 0) {
        cookies = cookies[0];
        if (e) {
          cookies.style.display = "none";
        } else {
          cookies.style.display = "flex";
        }
      }
    },
  },
  data() {
    return {
      showRedefinirsenha: "",
      show: false,
      show1: false,
      show2: false,
      drawer: false,
      showLogin: false,
      perfil: [
        {
          title: this.$t_("perfil"),
          icon: "mdi-account-circle mdi-color-gray",
          link: "Perfil",
        },
        {
          title: this.$t_("pedidos_capitalize"),
          icon: "mdi-cash-check",
          link: "MeusPedidos",
        },
        {
          title: this.$t_("trocar_senha"),
          icon: "mdi-lock-reset mdi-color-gray",
          link: "",
        },
        { title: this.$t_("sair"), icon: "mdi-logout mdi-color-gray", link: "sair" },
      ],
      menu: [
        { title: this.$t_("home"), icon: "mdi-home mdi-color-gray", link: "Home" },
        // { title: this.$t_('carrinho'), icon: 'mdi-basket mdi-color-gray', link: 'Checkout'},
        // { title: this.$t_('pedidos'),  icon: 'mdi-coin mdi-color-gray'  , link: 'MeusPedidos'}
      ],
      politicas: [
        {
          id: 1,
          title: this.$t_("politica_privacidade"),
          icon: "",
          name: "Politicas",
          link: "privacidade",
        },
        {
          id: 2,
          title: this.$t_("politica_cookies"),
          icon: "",
          name: "Politicas",
          link: "cookies",
        },
      ],
      mapa: [
        {
          title: this.$t_("perguntas_frequentes"),
          icon: "",
          name: "Informacoes",
          link: "perguntas-frequentes",
        },
      ],

      login: {
        email: "",
        senha: "",
        esqueciSenha: false,
        loginComum: false,
      },
    };
  },
  computed: {
    chekingPassword() {
      return this.$usuario.validaSenha(this.$usuario.cadastro.senha);
    },
  },
  methods: {
    goHome() {
      if (this.$route.name != "Home") {
        this.$axios.rota("Home");
      } else {
        this.$vuetify.goTo(0);
      }
    },
    goTrilha() {
      document.location.href = "/informacoes/decreto";
    },
    goPedidos() {
      if (this.$route.name != "MeusPedidos") {
        this.$axios.rota("MeusPedidos");
      } else {
        this.$vuetify.goTo(0);
      }
    },
    updateShowLogin(showDialog) {
      this.showLogin = showDialog;
    },
    operacao(link) {
      this.$usuario.menu.show = false;

      if (link == "") {
        this.showRedefinirsenha = true;
        return;
      }
      if (link != "sair") {
        this.$axios.rota(link);
        return;
      }

      sessionStorage.removeItem("token");
      localStorage.removeItem("token");

      this.$axios.destroy(true);
      this.$axios.rota("Home");
    },
    getLink(item) {
      this.$router.push({ name: item.name, params: { pagina: item.link } });
    },
  },
};
</script>

<style scoped>
.header-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-secundaria);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-principal);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}

.header-title {
  color: #fff !important;
}

.header-container1 {
  flex: 0 0 auto;
  width: 991px;
  height: 100px;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.header-container2 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.header-container2:hover {
  cursor: pointer;
}

header-container2-mobile {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

header-container2-mobile:hover {
  cursor: pointer;
}

.header-menuicon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  cursor: pointer;
  height: 24px;
  transition: 0.3s;
}

.header-menuicon:hover {
  fill: var(--dl-color-secundaria);
}

.header-image {
  max-height: 50px;
  margin-left: 0px;
}

.header-container3 {
  flex: 0 0 auto;
  width: 465px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.header-login {
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-end;
}

.header-login1 {
  fill: #333333ff;
  color: white !important;
  font-size: 14px;
  align-self: center;
}

.header-icon1 {
  fill: #d9d9d9;
  width: 24px;
  height: 24px;
}

.header-login2 {
  display: flex;
  align-self: center;
  margin-right: 15px;
  flex-direction: row;
  justify-content: flex-end;
}

.header-login3 {
  fill: #333333ff;
  color: var(--dl-color-gray-white);
  font-size: 14px;
  align-self: center;
}

.header-userlogin {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-right: 0px;
}

.header-contadorcarrinho {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.header-carrinho {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}

.header-qtd {
  top: 14px;
  left: auto;
  color: var(--dl-color-gray-900);
  right: 6px;
  width: 15px;
  bottom: auto;
  height: 15px;
  margin: auto;
  position: absolute;
  font-size: 10px;
  text-align: center;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-danger-500);
}

@media (max-width: 991px) {
  .header-container1 {
    width: 100%;
    height: 41px;
  }

  /* .header-container2 {
            width: 100%;
            justify-content: flex-start;
        } */

  .header-image {
    /* width: 94px;
    height: 37px; */
  }

  .header-container3 {
    display: none;
  }
}

@media (max-width: 767px) {
  /* .header-container2 {
            justify-content: flex-start;
        } */
}
</style>
